<section id="contact" data-aos="flip-left" data-aos-offset="-1200">
    <div class="section-contact">
        <div [class.contact-left-german]="germanActivated" class="contact-left">
            <h1 id="h1Contact" class="font-family-baloo">Contact</h1>
            <h2 id="h2Contact" class="font-family-montserrat">Got a problem to solve?</h2>
            <span id="textContact" class="font-family-montserrat">Contact me through this form, I am interested in hearing you, knowing your ideas and contributing to your projects with my work.</span>
            <p id="text2Contact" class="font-family-montserrat">Need a Frontend developer?<b> Contact me!</b></p>
            <img src="assets/images/ellipse11.png">
            <div (click)="scrollToTop()" id="button-up"></div>
        </div>
    
        <form id="contactForm" action="https://andreas-henner.com/send_mail/send_mail.php" method="post">
            <input id="nameInput" required class="font-family-montserrat" placeholder="Your name" type="text" name="name">
            <input id="emailInput" required class="font-family-montserrat" placeholder="Your email" type="email" name="email">
            <textarea id="textareaInput" required class="font-family-montserrat" placeholder="Your message" name="message"></textarea>
            <div [class.form-german]="germanActivated" class="checkbox-div font-family-baloo">
              <span id="error" class="error"></span>
              <span id="sucess" class="sucess"></span>
              <input required class="checkbox" type="checkbox">
              <p>
               <ng-container *ngIf="germanActivated; else englishText">Ich habe die <a (click)="scrollToTop()" [routerLink]="['imprint']">Datenschutzerklärung</a> gelesen und stimme der Verarbeitung meiner Daten wie beschrieben zu!</ng-container>
               <ng-template #englishText>I´ve read the <a (click)="scrollToTop()" [routerLink]="['imprint']">privacy Policy</a> and agree to the processing of my data as outlined!</ng-template>
              </p>
            </div> 
            <button [class.cursor-not-allowed]="!formValid" (click)="sendMail()" id="buttonContact" type="submit" class="font-family-montserrat">Say hello ;)</button>
        </form>
    </div>
    
</section>
