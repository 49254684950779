<section [class.skill-set-german]="germanActivated" id="skillSet" class="skill-set">
   
        <h1 id="skillSetTitle" [class.skill-set-h1-german]="germanActivated" class="font-family-baloo">Skill set</h1>
   
        <div class="skill-set-icons skill-set-icons-row">
            <img data-aos="flip-left" src="assets/images/angular.png" />
            <img data-aos="flip-left" src="assets/images/typescript.png" />
            <img data-aos="flip-left" src="assets/images/javascript.png" />
            <img data-aos="flip-left" src="assets/images/html.png" />
            <img data-aos="flip-left" src="assets/images/scrum.png" />
            <img class="img-position-relative" data-aos="flip-left" src="assets/images/firebase.png" />
            <img class="img-position-relative" data-aos="flip-left" src="assets/images/git.png" />
            <img class="img-position-relative" data-aos="flip-left" src="assets/images/css.png" />
            <img class="img-position-relative" data-aos="flip-left" src="assets/images/restApi.png" />
            <img class="img-position-relative" data-aos="flip-left" src="assets/images/materialDesign.png" />
        </div>
  </section>
