<section data-aos="fade-right" data-duration="500" class="about-me">
  <div class="section-div-about-me">
    <div [class.about-me-title-div-german]="germanActivated" class="font-family-baloo about-me-title-div">
      <h1 id="title" [class.about-me-title-german]="germanActivated" class="about-me-title">Let´s work <br>together</h1>
    </div>
  
   <img class="ellipse6" src="assets/images/ellipse6.png">
    <div class="about-me-right-section">
      <span id="textAboutMe">
        Hello, I am an aspiring junior Frontend developer and my name is Andreas Henner based in Laupheim near Ulm. 
        My focus is on programming languages, particularly on in-depth knowledge of JavaScript and TypeScript. 
        Additionally, I have experience with HTML, CSS for creating responsive user interfaces. 
        I am motivated to constantly learn new technologies and develop innovative solutions, 
        making me a great team player. I enjoy effective collaboration and contribution to creative solutions in a team.  
        I strive to deepen my knowledge and actively participate in projects to gain hands-on experience. 
        I am currently looking for an inspiring environment to further develop my skills. <br><br>
        If you think I’d be a good match for your projects,
        contact me!
      </span>
     <button [class.button-message-german]="germanActivated" class="button-message font-family-montserrat">
      <a id="buttonMessage" class="button-link" href="#contact" src="assets/images/ButtonSendAMessage.png">Send a message</a>
     </button>
    </div>
  </div>
   
  </section>
