import { Component } from '@angular/core';
import { LanguageService } from './../../language.service';


@Component({
  selector: 'app-about-me',
  standalone: true,
  imports: [],
  templateUrl: './about-me.component.html',
  styleUrl: './about-me.component.scss'
 
})
export class AboutMeComponent {
  germanActivated: boolean = false;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.languageService.germanActivated$.subscribe((value) => {
      this.germanActivated = value;
      this.translate();
    });
  }

  translate() {
    let textAboutMe: any = document.getElementById('textAboutMe');
    let h1: any = document.getElementById('h1');
    let buttonMessage: any = document.getElementById('buttonMessage');
    let title: any = document.getElementById('title');

    if (this.germanActivated) {
      textAboutMe.textContent = 'Hallo, ich bin ein angehender Junior Frontend Developer aus Laupheim bei Ulm. Mein Fokus liegt auf Programmiersprachen, insbesondere auf fundierten Kenntnissen in JavaScript und TypeScript, sowie Erfahrung in HTML und CSS für responsive Benutzeroberflächen. Ich bin motiviert, neue Technologien zu erlernen, innovative Lösungen zu entwickeln und effektiv in einem Team zusammenzuarbeiten. Ich bin aktiv auf der Suche nach Möglichkeiten, meine Fähigkeiten in einem inspirierenden Umfeld zu vertiefen. Wenn Sie der Meinung sind, dass ich gut zu Ihren Projekten passe, können Sie mich gerne kontaktieren!';
      h1.textContent = 'ENTWICKLER';
      buttonMessage.textContent = 'Nachricht';
      title.textContent = 'Lassen Sie uns zusammen arbeiten';
    }
    if (!this.germanActivated) {
      textAboutMe.textContent = 'Hello, I am an aspiring Junior Frontend Developer from Laupheim near Ulm. My focus lies in programming languages, particularly in-depth knowledge of JavaScript and TypeScript, along with experience in HTML and CSS for responsive user interfaces. I am motivated to learn new technologies, develop innovative solutions, and collaborate effectively in a team. I am actively seeking opportunities to deepen my skills in an inspiring environment. If you think I’d be a good match for your projects, feel free to contact me!';
      h1.textContent = 'DEVELOPER';
      buttonMessage.textContent = 'Send a message';
      title.textContent = 'Let´s work together';
    }
  }

}
