<img class="ellipse" src="assets/images/Ellipse 3.png"/>
<section class="title">
  
<div [class.section-title-german]="germanActivated" class="section-title">
  <div class="title-image">
   
     <img class="picture" src="assets/images/pictureAH.png"/>
  </div>
  
  <div class="title-under-div">
   
    <div class="h1-h2">
      <h2 id="h2" [class.h2-german]="germanActivated" class="font-family-montserrat font-weight-400">Hello! I am Andy</h2>
      <h1 class="font-family-baloo font-size-h1-frontend">FRONTEND</h1>
      <h1 [class.h1-german]="germanActivated" id="h1" class="font-family-baloo font-size-h1-developer">DEVELOPER</h1>
    </div>

    <div class="scroll">
      <img src="assets/images/scroll.png">
      <img src="assets/images/vector10.png">
    </div>

    <div class="links-about-me">
      <a class="github" href="https://github.com/AndreasHenner" [attr.target]="'_blank'"><img src="assets/images/githubButton.png"></a>
      <a href="#contact"><img class="email" src="assets/images/emailButton.png"></a> 
      <a class="linkedIn" href="https://www.linkedin.com/in/andreas-henner-762533215" [attr.target]="'_blank'"><img src="assets/images/linkedinButton.png"></a>
    </div>
    <img class="ellipse5" src="assets/images/ellipse5.png">
  </div>
</div>
   
  </section>
  
