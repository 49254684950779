<section [class.imprint-clicked-true]="imprintClicked" class="footer">
   <div class="section-footer">
    <img src="assets/images/2.png">
    <div class="footer-middle font-family-montserrat">
        <span>© Andreas Henner 2024</span>
        <a id="imprint" (click)="scrollToTop()" [routerLink]="['imprint']">Imprint</a>
    </div>

    <div class="footer-right">
        <a href="https://github.com/AndreasHenner" [attr.target]="'_blank'"><img src="assets/images/githubWhite.png"></a>
        <a href="#contact"><img src="assets/images/emailbuttonWhite.png"></a>
        <a href="https://www.linkedin.com/in/andreas-henner-762533215" [attr.target]="'_blank'"><img src="assets/images/linkedinbuttonWhite.png"></a>
    </div>
   </div>
      
</section>
